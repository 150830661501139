import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'
import NextImage from 'next/image'

import { Grid, Col } from 'components/grid'

import * as S from './styles'

const AwardedFaster: FC = memo(() => (
  <S.AwardedFaster>
    <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
      <Col col={{ tablet: 10, desktop: 10 }} start={{ tablet: 2, desktop: 2 }}>
        <Text as="h2" size="heading2" weight="medium">
          Como ter consórcio{' '}
          <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-default)">
            contemplado mais rápido
          </Text>
          ?
        </Text>
      </Col>
      <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 7, desktop: 7 }}>
        <NextImage
          src="/images/consortium/hands-up.png"
          alt="Mãos para cima indicando a si próprio"
          quality="100"
          priority
          fill
          style={{
            objectFit: 'contain'
          }}
        />
      </Col>
      <Col col={{ tablet: 4, desktop: 4 }} start={{ tablet: 2, desktop: 2 }}>
        <Text as="h3" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
          Dê seus lances
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          Os lances no consórcio são formas de antecipar sua contemplação. Veja como funciona:
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Modalidades
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          São duas: lance livre e lance fixo. No lance livre, o valor do lance não é pré-definido, 
          já no lance fixo, os valores mínimo e máximo são pré-definidos.
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Formas de pagamento
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          Podem ser feitas de duas formas, utilizando recursos próprios ou o lance embutido. 
          Ao optar pelo lance embutido, parte do crédito é descontado da sua carta de crédito.
        </Text>
      </Col>
      <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 2, desktop: 2 }}>
        <NextImage
          src="/images/consortium/man-signing-contract.png"
          alt="Homem assinando contrato"
          quality="100"
          priority
          fill
          style={{
            objectFit: 'contain'
          }}
        />
      </Col>
      <Col col={{ tablet: 4, desktop: 4 }} start={{ tablet: 7, desktop: 7 }}>
        <Text as="h3" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
          Plano Pontual
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          O Plano Pontual é uma forma exclusiva de acelerar sua conquista. Você pode resgatar seu bem 
          de forma programada após 6 ou 12 meses de pagamento, sem depender de lances!
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Grupos limitados
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          120 pessoas (auto) ou 240 pessoas (imóveis)
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Prazos vantajosos
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          60 vezes (auto) ou 120 vezes (imóveis)
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Sem cobrança adicional
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          Exclusivo CashMe
        </Text>
      </Col>
      <Col col={{ tablet: 5, desktop: 5 }} start={{ tablet: 7, desktop: 7 }}>
        <NextImage
          src="/images/consortium/couple-holding-key.png"
          alt="Casal segurando uma chave"
          quality="100"
          priority
          fill
          style={{
            objectFit: 'contain'
          }}
        />
      </Col>
      <Col col={{ tablet: 4, desktop: 4 }} start={{ tablet: 2, desktop: 2 }}>
        <Text as="h3" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
          Use seu FGTS no consórcio
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          O valor retido no seu FGTS pode ser usado no consórcio imobiliário, seja para fazer lances, liquidar saldos ou amortizar parcelas. Use o saldo a seu favor!
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Mesma titularidade
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          Para a conta do FGTS e a do consórcio.
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Localização do investimento
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          O titular não pode ter outro imóvel no mesmo município.
        </Text>
        <Text className="subtitle" weight="medium" color="var(--colors-neutral-text-default)">
          Situação financeira
        </Text>
        <Text color="var(--colors-neutral-text-weak)">
          O titular não pode ter financiamento ativo no SFH.
        </Text>
      </Col>
    </Grid>
  </S.AwardedFaster>
))

AwardedFaster.displayName = 'AwardedFaster'

export default AwardedFaster
