import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, space, colors } = theme

export const AwardedFaster = styled.section`
  background-color: ${colors['neutral-background-medium']};
  width: 100%;
  padding: ${space['spacing-xl']} 0;

  h2 {
    margin-bottom: ${space['spacing-md']};
  }

  h3 {
    margin: ${space['spacing-md']} 0;
  }

  p {
    margin-bottom: ${space['spacing-sm']};
  }

  p.subtitle {
    margin-bottom: ${space['spacing-xxxs']};
  }

  & > div > div:nth-child(2),
  & > div > div:nth-child(4),
  & > div > div:nth-child(6) {
    position: relative;
    height: 230px;
  }

  @media (${media.tablet}) {
    padding: ${space['spacing-giga']} 0;

    & > div > div:nth-child(2),
    & > div > div:nth-child(4),
    & > div > div:nth-child(6) {
      height: unset;
    }
    
    & > div > div:nth-child(2) { order: 1; }
    & > div > div:nth-child(3) { order: 0; }
    & > div > div:nth-child(4) { order: 2; }
    & > div > div:nth-child(5) { order: 3; }
    & > div > div:nth-child(6) { order: 5; }
    & > div > div:nth-child(7) { order: 4; }
  }
`
